import React from 'react'
import useEditProduct from './hooks'
import { useMutation } from '@apollo/client'

import { ProductEditor } from './ProductEditor'
import Title from '../components/Title'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { useNavigate } from 'react-router-dom'
import { AddNewProductDocument, RefetchProductsDocument } from '../generated'

const AddProductScreen = () => {
  const {
    product,
    handleValueChange,
    handleLabelChange,
    handleImageChange,
    handleTextChange,
  } = useEditProduct()
  const [addNewProduct] = useMutation(AddNewProductDocument)
  const navigate = useNavigate()

  const handleProductSubmit = () => {
    const { id, active, labels, ...fields } = product
    addNewProduct({
      variables: {
        input: {
          ...fields,
          labelIds: labels.map((l) => l.id),
          price: parseInt(product.price),
          stockLevelAlert: 0,
        },
      },
      refetchQueries: [{ query: RefetchProductsDocument }],
    }).then((data) => navigate(`/products/${data.data.addNewProduct.id}`))
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Title>Create Product</Title>
        </Grid>
      </Grid>

      <ProductEditor
        product={product}
        editMode={true}
        onValueChange={handleValueChange}
        onLabelChange={handleLabelChange}
        onImageChange={handleImageChange}
        onTextChange={handleTextChange}
      />
      <Button color="primary" variant="contained" onClick={handleProductSubmit}>
        Save
      </Button>
    </>
  )
}

export default AddProductScreen
