import { useState, useEffect, useCallback } from 'react'
import { Category, Locale, ProductOrigin } from '../generated'

const emptyProduct = {
  images: [],
  labels: [],
  texts: [
    { name: '', description: '', allergens: '', locale: Locale.En },
    { name: '', description: '', allergens: '', locale: Locale.Se },
  ],
  price: 0,
  category: Category.Bowl,
  origin: ProductOrigin.Internal,
  active: true,
}

const useEditProduct = (initialProduct) => {
  const [product, setProduct] = useState(initialProduct || emptyProduct)

  const resetValues = useCallback(() => {
    setProduct({
      id: initialProduct.id,
      category: initialProduct.category,
      price: initialProduct.price,
      origin: initialProduct.origin,
      images: initialProduct.images,
      labels: initialProduct.labels,
      texts: initialProduct.texts,
      active: initialProduct.active,
    })
  }, [initialProduct])

  useEffect(() => {
    if (initialProduct) {
      resetValues()
    }
  }, [initialProduct, resetValues])

  const handleImageChange = useCallback((value, imageType) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      images: [
        ...prevProduct.images.filter((img) => img.imageType !== imageType),
        { imageType, imageUrl: value },
      ],
    }))
  }, [])

  const handleLabelChange = useCallback((checked, labelId, labelType) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      labels: checked
        ? [{ id: labelId, labelType }, ...prevProduct.labels]
        : prevProduct.labels.filter((l) => l.id !== labelId),
    }))
  }, [])

  const handleTextChange = useCallback((value, field, locale) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      texts: prevProduct.texts.map((t) =>
        t.locale === locale ? { ...t, [field]: value } : t
      ),
    }))
  }, [])

  const handleValueChange = useCallback((value, field) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      [field]: value,
    }))
  }, [])

  return {
    product,
    handleValueChange,
    handleLabelChange,
    handleImageChange,
    handleTextChange,
    resetValues,
  }
}

export default useEditProduct
