import React, { useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { format } from 'date-fns'

import { useMutation, useQuery } from '@apollo/client'

import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
import CheckIcon from '@material-ui/icons/Check'
import Switch from '@material-ui/core/Switch'

import Spinner from '../../components/spinner'
import Title from '../../components/Title'
import {
  AddChannelDocument,
  AddPaymentMethodDocument,
  CopyMachineConfigDocument,
  CopyMachineConfigInput,
  DeleteChannelDocument,
  MachineById_MachineInfoDocument,
  MachinesDocument,
  PaymentMethod,
  RemovePaymentMethodDocument,
  SetFridgeDocument,
  SetActiveDocument,
  SetLocationDocument,
} from '../../generated'
import { ChannelConfiguration } from './ChannelConfiguration'
import { CopyMachineDialog } from './CopyMachineDialog'

import { CardTerminals } from './CardTerminals'
import { Checkbox, TextField } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  backArrow: {
    color: theme.palette.primary.main,
    '&:hover': { textDecoration: 'underline' },
  },
  infoRow: { marginTop: '0.5rem', marginBottom: '0.5rem' },
  label: { fontWeight: 600 },
  formControl: { minWidth: 250 },
}))

function debounce(func:any, wait:number) {
  let timeout:any;
  return function executedFunction(...args:any) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export const SingleMachineScreen = () => {

  const classes = useStyles()
  const params = useParams()
  const machineId = params.id || ''
  const [copyMachineModalOpen, setCopyMachineModalOpen] = useState(false)
  const { loading, error, data } = useQuery(MachineById_MachineInfoDocument, {
    variables: { id: machineId },
  })
  const navigate = useNavigate()
  const [addChannel] = useMutation(AddChannelDocument)
  const [deleteChannel] = useMutation(DeleteChannelDocument)
  const [addPaymentMethod] = useMutation(AddPaymentMethodDocument)
  const [removePaymentMethod] = useMutation(RemovePaymentMethodDocument)
  const [copyMachineConfig] = useMutation(CopyMachineConfigDocument)
  const [setFridge] = useMutation(SetFridgeDocument)
  const [setActive] = useMutation(SetActiveDocument)
  const [saveLocation] = useMutation(SetLocationDocument)
  const [location, setLocation] = useState(data?.machineById.location || '');

  if (loading || !data) return <Spinner />
  if (error || !machineId) return <p>Error {JSON.stringify(error, null, 2)}</p>

  const handleTogglePaymentMethod =
    (method: PaymentMethod) =>
    (_e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const args = {
        variables: { input: { machineId, method } },
        refetchQueries: [
          {
            query: MachineById_MachineInfoDocument,
            variables: { id: machineId },
          },
        ],
      }
      if (checked) addPaymentMethod(args)
      else removePaymentMethod(args)
    }

  const handleAddChannel = (channel: number) => {
    return addChannel({
      variables: { input: { machineId, channel } },
      refetchQueries: [
        {
          query: MachineById_MachineInfoDocument,
          variables: { id: machineId },
        },
      ],
    })
  }

  const handleRemoveChannel = (channel: number) => {
    return deleteChannel({
      variables: { input: { machineId, channel } },
      refetchQueries: [
        {
          query: MachineById_MachineInfoDocument,
          variables: { id: machineId },
        },
      ],
    })
  }
  const handleSetFridge = (isFridge: number) => {
    return setFridge({
      variables: { input: { machineId, isfridge: isFridge } },
      refetchQueries: [
        {
          query: MachineById_MachineInfoDocument,
          variables: { id: machineId },
        },
      ],
    })
  }

  const handleSetActive = (active: boolean) => {
    return setActive({
      variables: { input: { machineId, active } },
      refetchQueries: [
        {
          query: MachineById_MachineInfoDocument,
          variables: { id: machineId },
        },
      ],
    })
  }

  const handleSetLocation = (location: string) => {
    return saveLocation({
      variables: { input: { machineId, location } },
      refetchQueries: [
        {
          query: MachineById_MachineInfoDocument,
          variables: { id: machineId },
        },
      ],
    })
  }

  const handleCopyMachineConfig = async (
    config: Omit<CopyMachineConfigInput, 'machineId'>
  ) => {
    const res = await copyMachineConfig({
      variables: { input: { ...config, machineId } },
      refetchQueries: [{ query: MachinesDocument }],
    })
    if (res.data?.copyMachineConfig)
      navigate(`/machines/${res.data.copyMachineConfig.id}`)
  }

  const machine = data.machineById

  const machineInfo = [
    { label: 'ID', value: machine.id },
    { label: 'Location', value: machine.location },
    { label: 'Active', value: machine.active ? 'TRUE' : 'FALSE' },
    {
      label: 'Launch Date',
      value: machine.launchDate
        ? format(new Date(machine.launchDate), 'dd-MM-yyyy')
        : '?',
    },
  ]

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Link className={classes.backArrow} to="/machines">
            &larr; Go back
          </Link>
          <Title>{machine.location}</Title>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {machineInfo.map(({ label, value }) => (
            <p className={classes.infoRow}>
              <span className={classes.label}>{label}</span>: {value}
            </p>
          ))}
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled ={false}
            onChange={(event) => setLocation(event.target.value)}
            value={location}
            label="Location"
          />
          <IconButton
            onClick={() => handleSetLocation(location)}
          >
            <CheckIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                disabled ={false}
                onChange={(event) => handleSetFridge(event.target.checked ? 1 : 0)}
                checked={data.machineById.isfridge ? true : false}
              />
            }
            label="Is Fridge"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                disabled ={false}
                onChange={(event) => handleSetActive(event.target.checked)}
                checked={data.machineById.active}
              />
            }
            label="Active"
          />
        </Grid>        
        <Grid item xs={12}>
          <CardTerminals
            machineId={data.machineById.id}
            pointOfServiceId={data.machineById.pointOfServiceId}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h2 style={{ marginBottom: '0' }}>Payment Methods</h2>
        </Grid>
        {Object.values(PaymentMethod).map((paymentMethod) => (
          <FormControlLabel
            control={
              <Switch
                color="primary"
                onChange={handleTogglePaymentMethod(paymentMethod)}
                checked={machine.paymentMethods.includes(paymentMethod)}
              />
            }
            label={paymentMethod}
            labelPlacement="top"
            style={{ marginLeft: '6px' }}
          />
        ))}
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ChannelConfiguration
            onAddChannel={handleAddChannel}
            onRemoveChannel={handleRemoveChannel}
            channels={machine.channels}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CopyMachineDialog
            open={copyMachineModalOpen}
            onOpen={() => setCopyMachineModalOpen(true)}
            onClose={() => setCopyMachineModalOpen(false)}
            onSubmit={handleCopyMachineConfig}
          />
        </Grid>
      </Grid>
    </>
  )
}
