import React from 'react'
import './App.css'

import { Routes, Route } from 'react-router-dom'
import { Protected, LoginScreen } from './Auth'

import { InventoryScreen } from './Inventory'
import { MenuItemsScreen } from './MenuItems'
import {
  ProductsScreen,
  SingleProductScreen,
  AddProductScreen,
} from './Products'
import { BatchGenerateScreen, DiscountsScreen } from './Discounts'
import { OrdersScreen, SingleOrderScreen } from './Orders'
import { MachinesScreen, SingleMachineScreen } from './Machines'
import { ErrorsScreen } from './Errors'
import { CustomerssScreen } from './Customers'

import { Dashboard } from './Dashboard'
import { ApolloWrapper } from './ApolloWrapper'

const App = () => {
  const protectedRoutes = [
    { path: '/products/create', component: AddProductScreen },
    { path: '/products/:id', component: SingleProductScreen },
    { path: '/inventory', component: InventoryScreen },
    { path: '/menus', component: MenuItemsScreen },
    { path: '/products', component: ProductsScreen },
    { path: '/discounts/batch', component: BatchGenerateScreen },
    { path: '/discounts', component: DiscountsScreen },
    { path: '/machines/:id', component: SingleMachineScreen },
    { path: '/machines', component: MachinesScreen },
    { path: '/orders/:id', component: SingleOrderScreen },
    { path: '/orders', component: OrdersScreen },
    { path: '/customers', component: CustomerssScreen },
    { path: '/errors', component: ErrorsScreen },
    { path: '/', component: Dashboard },
  ]

  return (
    <Routes>
      <Route path="/login" element={<LoginScreen />} />
      <Route path="/" element={<ApolloWrapper />}>
        {protectedRoutes.map(({ path, component: C }) => (
          <Route path={path} key={path} element={<Protected component={C} />} />
        ))}
      </Route>
    </Routes>
  )
}

export default App
