import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
  concat,
  NormalizedCacheObject,
} from '@apollo/client'

const uri = `${process.env.REACT_APP_PROXY_URI || process.env.REACT_APP_API_URI + '/graphql'}`
console.log({ uri })

const httpLink = new HttpLink({
  uri,
  credentials: 'include',
})

let client: ApolloClient<NormalizedCacheObject>

export const createClient = (apiKey: string) => {
  if (client) return client

  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: { ...headers, Authorization: apiKey },
    }))
    return forward(operation)
  })

  client = new ApolloClient({
    link: concat(authMiddleware, httpLink),
    cache: new InMemoryCache(),
  })

  return client
}
