import React, { useState, useEffect } from 'react'

import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Pagination from '@material-ui/lab/Pagination'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'

import Title from '../components/Title'
import Spinner from '../components/spinner'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

// import CreateDiscount from './CreateDiscount'
// import EditDiscount from './EditDiscount'
import {
  ConsentedCustomersDocument,
  ConsentedCustomersQuery,
  ConsentedCustomersCountDocument,
  ConsentedCustomersCountQuery
  // MachinesDocument,
} from '../generated'

const useStyles = makeStyles((theme) => ({
  table: { minWidth: 650 },
  toLeft: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
  },
}))


const CustomersTable = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles()
  const headers = [
    'Created',
    'Consent Marketing',
    'Marketing consent at',
    'Phone',
    'Consent phone',
    'Consent phone at',
    'Email',
    'Consent email',
    'Consent email at',
  ]
  return (
    <Paper style={{ width: '100%' }}>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {headers.map((h) => (
                <TableCell key={h}>{h}</TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

const CustomersScreen = () => {
  const [open, setOpen] = useState(false)
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }
  
  const ConsentedCustomers = () => {
    const variables = {
      input: {
        Limit: pageSize,
        Offset: (page - 1) * pageSize,
      },
    };
    const { loading, data, error, refetch } = useQuery(ConsentedCustomersDocument, { variables })
    const { data: countData, refetch: countRefetch } = useQuery(ConsentedCustomersCountDocument)
  
    useEffect(() => {
      if (data) refetch()
    }, [data, refetch])
    useEffect(() => {
      if (countData) {
        countRefetch()
        console.log('countData', countData)
        setTotal(countData.consentedCustomersCount || 0)
      }
    }, [countData, countRefetch])
  
    // const formatDate = (date: string) => date.replace('T', ' ').slice(0, 16)
  
    if (loading || !data) return <><Spinner /></>
    if (error) return <p>Error : {JSON.stringify(error, null, 2)}</p>
  
    return (
      <>
        {data.consentedCustomers.map((customer) => (
          <TableRow key={customer.id}>
            <TableCell>{customer.createdAt}</TableCell>
            <TableCell>{customer.marketingConsent ? 'Yes' : 'No'}</TableCell>
            <TableCell>{customer.consentedToMarketingAt ? customer.consentedToMarketingAt : '' }</TableCell>
            <TableCell>{customer.phoneNumber}</TableCell>
            <TableCell>{customer.consentPhone ? 'Yes' : 'No' }</TableCell>
            <TableCell>{customer.consentPhoneAt ? customer.consentPhoneAt : '' }</TableCell>
            <TableCell>{customer.email}</TableCell>
            <TableCell>{customer.consentEmail ? 'Yes' : 'No' }</TableCell>
            <TableCell>{customer.consentEmailAt ? customer.consentEmailAt : '' }</TableCell>
          </TableRow>
        ))}
        {/* {editModalDiscount && (
          <EditDiscount
            open={!!editModalDiscount}
            onClose={() => setEditModalDiscount(null)}
            discount={editModalDiscount}
            activeOnly={activeOnly}
          />
        )} */}
      </>
    )
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Title>Customers</Title>
        </Grid>
        <Grid item xs={12}>
        {/*
          <Button
            color="primary"
            variant="contained"
            onClick={() => setOpen(true)}
            style={{ margin: '12px 0' }}
          >
            + ADD DISCOUNT
          </Button>

          <Link to="/discounts/batch">
            <Button
              color="primary"
              variant="contained"
              onClick={() => setOpen(true)}
              style={{ margin: '12px' }}
            >
              Batch Generate
            </Button>
          </Link>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                onChange={() =>
                  setActiveOnly((prevActiveOnly) => !prevActiveOnly)
                }
                value={activeOnly}
                checked={activeOnly}
              />
            }
            label="Active Only"
            labelPlacement="end"
            style={{ marginLeft: '6px' }}
          /> */}
        </Grid> 

        <CustomersTable>
          <ConsentedCustomers />
        </CustomersTable>
        <Grid item xs={12}>
          <Pagination siblingCount={6} count={Math.ceil(total/pageSize)} page={page} onChange={handleChange} />
        </Grid>
      </Grid>

      {/* {open && (
        <CreateDiscount open={open} setOpen={setOpen} activeOnly={activeOnly} />
      )} */}
    </>
  )
}

export default CustomersScreen
